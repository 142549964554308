<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        المسجلين خارج المركز
      </h1>
    </div>

    <div class="mt-4 w-full relative">
      <div class="h-8 w-full flex justify-between items-center">
        <button
          @click="toggle_filter()"
          class="flex items-center hover:text-blue-600 cursor-pointer w-full"
        >
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
            />
          </svg>
          <span class="mr-2"> فرز </span>
        </button>

        <button
          v-if="in_search"
          @click="cancel_filter()"
          class="flex items-center justify-center w-28 md:w-48 text-center py-2 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <span class="ml-2"> إلغاء الفرز </span>

          <svg
            class="h-6 w-6 stroke-current"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6974 6.69598L6.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.6974 6.69598L18.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-class="transform opacity-0 scale-y-0"
        enter-to-class="transform opacity-100 scale-y-100"
        leave-active-class="transition ease-in-out duration-75"
        leave-class="transform opacity-100 scale-y-100"
        leave-to-class="transform opacity-0 scale-y-0"
      >
        <div
          v-if="filter_box"
          class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8"
        >
          <div class="grid grid-cols-4 gap-8">
            <div class="input">
              <label
                for="filter_citizen_number"
                class="block text-sm font-medium"
              >
                رقم المنظومة
              </label>
              <input
                type="tel"
                id="filter_citizen_number"
                placeholder="أكتب رقم المنظومة."
                v-model="filter_citizen_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_first_name"
                class="block text-sm font-medium"
              >
                الاسم الاول
              </label>
              <input
                type="text"
                id="citizen_filter_first_name"
                placeholder="أكتب الاسم الاول."
                v-model="filter_first_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_middle_name"
                class="block text-sm font-medium"
              >
                اسم الاب
              </label>
              <input
                type="text"
                id="citizen_filter_middle_name"
                placeholder="أكتب اسم الاب."
                v-model="filter_middle_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_last_name"
                class="block text-sm font-medium"
              >
                اللقب
              </label>
              <input
                type="text"
                id="citizen_filter_last_name"
                placeholder="أكتب اللقب."
                v-model="filter_last_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_mother_name"
                class="block text-sm font-medium"
              >
                اسم الام
              </label>
              <input
                type="text"
                id="citizen_filter_mother_name"
                placeholder="أكتب اسم الام."
                v-model="filter_mother_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="filter_passport_number"
                class="block text-sm font-medium"
              >
                رقم جواز السفر
              </label>
              <input
                type="text"
                id="filter_passport_number"
                placeholder="رقم جواز السفر."
                v-model="filter_passport_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label for="nationalityid" class="block text-sm font-medium">
                اختر الجنسية
              </label>
              <select
                id="nationalityid"
                name="nationalityid"
                v-model="nationalityid"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option disabled selected>الجنسيات</option>
                <option
                  v-for="nationality in nationalities_data"
                  :key="nationality.id"
                  v-bind:value="nationality.id"
                >
                  {{ nationality.name_ar }}
                </option>
              </select>
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="citizen_filter_national_number"
                class="block text-sm font-medium"
              >
                الرقم الوطني
              </label>
              <input
                type="number"
                id="citizen_filter_national_number"
                placeholder="أكتب الرقم الوطني."
                v-model="filter_national_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_family_paper_number"
                class="block text-sm font-medium"
              >
                رقم ورقة العائلة
              </label>
              <input
                type="text"
                id="filter_family_paper_number"
                placeholder="أكتب رقم ورقة العائلة."
                v-model="filter_family_paper_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_registration_number"
                class="block text-sm font-medium"
              >
                رقم القيد
              </label>
              <input
                type="text"
                id="filter_registration_number"
                placeholder="أكتب الاسم."
                v-model="filter_registration_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_brochure_number"
                class="block text-sm font-medium"
              >
                رقم الكتيب
              </label>
              <input
                type="text"
                id="filter_brochure_number"
                placeholder="رقم الكتيب."
                v-model="filter_brochure_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="filter_phone_number"
                class="block text-sm font-medium"
              >
                رقم الهاتف
              </label>
              <input
                type="tel"
                id="filter_phone_number"
                placeholder="أكتب رقم الهاتف."
                v-model="filter_phone_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
            <div class="inline w-full md:w-auto cursor-pointer">
              <button
                @click="do_search()"
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                بحث
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-1/12 px-4 py-3 hidden lg:block">
              الرقم
            </div>
            <div scope="col" class="w-7/12 md:w-3/12 px-2 py-3">الاسم</div>
            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
              رقم المنظومة
            </div>

            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
              رقم القيد
            </div>

            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
              البلدية
            </div>

            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
              المركز الصحي
            </div>

            <div scope="col" class="w-5/12 md:w-2/12 px-2 py-3">الإجراءات</div>
          </div>

          <div v-if="total == 0" class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center">
            <span v-if="in_search">
               لاتوجد نتائج متوافقة معا اختيارات الفرز.
            </span>

            <span v-else>
              لايوجد مسجلين
            </span>
            
          </div>


          <div
            v-else
            v-for="(citizen, personIdx) in citizens_data"
            :key="citizen.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >
            <div
              class="w-1/12 px-4 py-4 whitespace-nowrap text-sm font-medium hidden md:block"
            >
              {{ personIdx + 1 }}
            </div>
            <div
              class="w-7/12 md:w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate"
            >
              {{ citizen.first_name }} {{ citizen.middle_name }}
              {{ citizen.last_name }}
            </div>
            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.citizen_number }}
            </div>

            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.registration_number }}
            </div>

            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.muncipal_name }}
            </div>

            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.helth_Center_name }}
            </div>

            <div class="w-5/12 md:w-2/12 px-2 py-4">
              <div class="w-full flex items-center justify-between">
                <!-- <router-link title="العائلة" :to="{ name: 'citizenFamily', params: { id: citizen.id, registration_number:citizen.registration_number },}" class="mx-2">
                                    <svg class="h-6 w-6  fill-current hover:text-blue-600" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        width="79.538px" height="79.538px" viewBox="0 0 79.538 79.538" style="enable-background:new 0 0 79.538 79.538;"
                                        xml:space="preserve">
                                        <g>
                                            <path  d="M28.635,32.496c0,2.02-1.639,3.661-3.664,3.661c-2.025,0-3.669-1.642-3.669-3.661
                                                c0-2.03,1.644-3.666,3.669-3.666C26.996,28.83,28.635,30.466,28.635,32.496z M25.359,57.584l0.785,13.515
                                                c0,0.477,0.386,0.865,0.857,0.865c0.479,0,0.859-0.389,0.859-0.865l0.738-13.515h2.392l-2.473-15.151l4.974,9.906
                                                c0.187,0.439,0.689,0.647,1.129,0.455c0.435-0.181,0.642-0.684,0.451-1.123c0,0-3.648-9.864-4.549-11.703
                                                c-0.891-1.838-1.781-2.957-5.202-2.957h-0.699c-3.415,0-4.306,1.118-5.207,2.951c-0.896,1.844-4.557,11.702-4.557,11.702
                                                c-0.179,0.44,0.023,0.948,0.458,1.129c0.44,0.192,0.942-0.021,1.129-0.455l4.979-9.906l-2.475,15.151h2.39l0.743,13.515
                                                c0,0.477,0.386,0.865,0.86,0.865c0.481,0,0.862-0.389,0.862-0.865l0.737-13.515H25.359z M54.582,28.82
                                                c-2.029,0-3.671,1.636-3.671,3.666c0,2.02,1.646,3.656,3.671,3.656c2.02,0,3.65-1.636,3.65-3.656
                                                C58.232,30.456,56.602,28.82,54.582,28.82z M58.378,50.392l0.243-7.974l4.474,9.911c0.187,0.44,0.688,0.643,1.129,0.456
                                                c0.436-0.182,0.647-0.684,0.461-1.129c0,0-3.153-9.859-4.055-11.703c-0.896-1.833-2.283-2.952-5.706-2.952h-0.699
                                                c-3.412,0-4.815,1.119-5.711,2.952c-0.901,1.843-4.052,11.703-4.052,11.703c-0.185,0.445,0.022,0.947,0.458,1.129
                                                c0.44,0.187,0.942-0.021,1.129-0.456l4.479-9.911l0.249,7.974l-0.71,20.023c-0.057,0.798,0.544,1.486,1.331,1.538
                                                c0.042,0,0.078,0.006,0.104,0.006c0.751,0,1.383-0.575,1.439-1.336l1.632-15.488l1.631,15.488c0.052,0.761,0.684,1.336,1.439,1.336
                                                c0.031,0,0.067,0,0.099-0.006c0.797-0.052,1.388-0.74,1.336-1.538L58.378,50.392L58.378,50.392z M39.77,51.096
                                                c-1.24,0-2.247,1.005-2.247,2.247c0,1.243,1.007,2.242,2.247,2.242c1.24,0,2.252-0.999,2.252-2.242
                                                C42.022,52.101,41.01,51.096,39.77,51.096z M39.77,64.337c0,0,1.012,7.031,1.012,7.037c0.132,0.336,0.461,0.585,0.854,0.585
                                                c0.409,0,0.743-0.27,0.865-0.638c0.01-0.016,0.031-0.435,0.01-0.502l-0.414-8.637l0.127-2.962c0.116-1.01,0.137-0.715,1.289-1.398
                                                c1.171-0.704,3.167-3.79,3.26-3.945c0.096-0.15,0.282-0.425,0.318-0.596c0.055-0.315-0.147-0.606-0.455-0.668
                                                c-0.238-0.042-0.436,0.083-0.583,0.249c-0.155,0.16-2.449,3.215-4.173,3.51c-0.381-0.057-0.8-0.259-1.893-0.259h-0.435
                                                c-1.093,0-1.507,0.202-1.893,0.259c-1.725-0.295-4.019-3.35-4.174-3.51c-0.147-0.166-0.344-0.291-0.577-0.249
                                                c-0.308,0.062-0.51,0.357-0.451,0.668c0.031,0.171,0.218,0.445,0.313,0.596c0.096,0.155,2.094,3.241,3.259,3.945
                                                c1.152,0.684,1.173,0.389,1.29,1.398l0.122,2.962l-0.409,8.637c-0.021,0.072,0,0.486,0.01,0.502
                                                c0.122,0.368,0.45,0.638,0.865,0.638c0.388,0,0.722-0.249,0.854-0.585C38.763,71.368,39.77,64.337,39.77,64.337 M78.909,36.747
                                                c1.108-1.584,0.714-3.774-0.881-4.883L45.613,9.313c-3.329-2.319-8.357-2.319-11.682,0L1.509,31.864
                                                c-1.592,1.108-1.988,3.299-0.88,4.883c1.108,1.59,3.291,1.989,4.883,0.881l32.417-22.551c0.914-0.632,2.763-0.632,3.677,0
                                                l32.415,22.551c0.611,0.424,1.315,0.632,1.999,0.632C77.138,38.254,78.23,37.726,78.909,36.747z"/>
                                        </g>

                                    </svg>
                                </router-link> -->

                <router-link
                  title="صرف لقاح"
                  :to="{
                    name: 'cashing_citizens_vaccine',
                    params: {
                      vaccination_center_id: vaccination_center_id,
                      id: citizen.id,
                      citizen_name:
                        citizen.first_name + ' ' + citizen.last_name,
                      day: citizen.day,
                      month: citizen.month,
                      year: citizen.year,
                    },
                  }"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 fill-current hover:text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 3"
                    viewBox="0 0 64 64"
                  >
                    <path
                      d="M51.707,1.293a1,1,0,0,0-1.414,0l-3,3a1,1,0,0,0,0,1.414L50.086,8.5,47,11.586,44.707,9.293a1,1,0,0,0-1.414,0l-29,29a1,1,0,0,0,0,1.414L16.086,41.5l-2.793,2.793a1,1,0,0,0,0,1.414L15.086,47.5,1.293,61.293l1.414,1.414L16.5,48.914l1.793,1.793a1,1,0,0,0,1.414,0L22.5,47.914l1.793,1.793a1,1,0,0,0,1.414,0L44.715,30.7A2.951,2.951,0,0,0,46,31v2H45a4,4,0,0,0-4,4V59a4,4,0,0,0,4,4H57a4,4,0,0,0,4-4V37a4,4,0,0,0-4-4H56V31a3,3,0,0,0,0-6V24a3,3,0,0,0-1.826-2.76l.533-.533a1,1,0,0,0,0-1.414L52.414,17,55.5,13.914l2.793,2.793a1,1,0,0,0,1.414,0l3-3a1,1,0,0,0,0-1.414ZM19,48.586,15.414,45,17.5,42.914,21.086,46.5Zm6-1L16.414,39l13-13H43.78A2.981,2.981,0,0,0,43,28a2.926,2.926,0,0,0,.081.666l-1.374-1.373-1.414,1.414L42.086,30.5,39,33.586l-3.293-3.293-1.414,1.414L37.586,35,34.5,38.086l-1.793-1.793-1.414,1.414L33.086,39.5,30,42.586l-3.293-3.293-1.414,1.414L28.586,44ZM59,55H48V40H59ZM55,35h2a2,2,0,0,1,2,2v1H47a1,1,0,0,0-1,1V56a1,1,0,0,0,1,1H59v2a2,2,0,0,1-2,2H45a2,2,0,0,1-2-2V37a2,2,0,0,1,2-2h2a1,1,0,0,0,1-1V31h6v3A1,1,0,0,0,55,35Zm2-7a1,1,0,0,1-1,1H46a1,1,0,0,1,0-2H56A1,1,0,0,1,57,28Zm-9-3V24a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v1Zm3.586-4H49a3,3,0,0,0-3,3H31.414L44,11.414,52.586,20ZM51,15.586,48.414,13,51.5,9.914,54.086,12.5Zm8-1L49.414,5,51,3.414,60.586,13Z"
                    />
                  </svg>
                </router-link>

                <button
                  title="طلب نقل"
                  class=""
                  @click="transferRequests(citizen)"
                >
                  <svg
                    class="h-6 w-6 fill-current hover:text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="24"
                    enable-background="new 0 0 32 24"
                  >
                    <path
                      d="M32 20H7.992v4L0 18l7.992-6v4H32zM0 8h24v4l8-6-8-6v4H0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_number"
        :per-page="page_size"
        :records="total"
        @paginate="getCitizensNotBelong"
        class="z-10"
      />
    </div>

   
  </div>
</template>

<script>
import CitizensService from "@/services/CitizensService";
import NationalitiesService from "@/services/NationalitiesService";

import VaccinationCentersService from "@/services/VaccinationCentersService";
import MunicipalsService from "@/services/MunicipalsService";

import { required } from "vuelidate/lib/validators";

export default {
  computed: {},

  validations: {
    municipal_id: {
      required,
    },

 
  },

  data() {
    return {
      citizens_data: {},
      administrators_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,

      filter_box: false,

      filter_search: "",
      filter_administrator_id: "",

      vaccination_center_id: this.$store.state.auth.role.guid,

      nationalityid: "",

      filter_national_number: "",

      filter_mother_name: "",
      filter_family_paper_number: "",
      filter_registration_number: "",
      filter_brochure_number: "",
      filter_passport_number: "",
      filter_phone_number: "",
      filter_citizen_number: "",
      filter_first_name: "",
      filter_middle_name: "",
      filter_last_name: "",

      transfer_model: false,
      prepare_center_to_transfer: "",

      municipals_data: {},
      vaccination_centers_data: {},

  

      municipal_id: "",

      in_search: false,
    };
  },

  created() {
    this.getCitizensNotBelong();
    this.getNationalites();
    this.getMunicipals();


    console.log(this.$store.state.auth.role.guid)
  },

  // watch: {
  //   municipal_id: function () {
  //     this.transfer_data.municipal_id = this.municipal_id;
  //     this.getVaccinationCenters(this.municipal_id);
  //   },
  // },

  methods: {
    cancel_filter() {
      this.in_search = false;

      this.filter_national_number = "";
      this.filter_first_name = "";
      this.filter_middle_name = "";
      this.filter_last_name = "";
      this.filter_mother_name = "";
      this.filter_family_paper_number = "";
      this.filter_registration_number = "";
      this.filter_brochure_number = "";
      this.filter_passport_number = "";
      this.filter_phone_number = "";

      this.page_number = 1;
      this.page_size = 10;
      this.filter_citizen_number = "";
      this.nationalityid = "";

      this.getCitizensNotBelong();
    },

    do_search() {
      this.page_number = 1;
      this.getCitizensNotBelong();
      this.filter_box = !this.filter_box;
      this.in_search = true;
    },







    transferRequests(citizen) {




      let data = {
         
        citizen_id: citizen.id,
        from_the_health_center_id: citizen.health_Centerid,
        to_the_health_center_id: this.$store.state.auth.role.guid,
        reason: '',
         
        };


      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل انت متأكد من انك بحاجة لي نقل المسجل",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، نقل",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            CitizensService.transferRequests(data)
              .then(() => {
                // let i = this.citizens_data.map((item) => item.id).indexOf(id); // find index of your object
                // this.citizens_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: "تم ارسال الطلب بنجاح.",
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },




    getVaccinationCenters(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      VaccinationCentersService.getVaccinationCentersByMunicipalWithoutPagination(
        id
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.vaccination_centers_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getMunicipals() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsService.getMunicipalsWithoutPagination()
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.municipals_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },

    getCitizensNotBelong() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      CitizensService.getCitizensNotBelong(
        this.filter_national_number,
        this.filter_first_name,
        this.filter_middle_name,
        this.filter_last_name,
        this.filter_mother_name,
        this.filter_family_paper_number,
        this.filter_registration_number,
        this.filter_brochure_number,
        this.filter_passport_number,
        this.filter_phone_number,
        this.vaccination_center_id,
        this.page_number,
        this.page_size,
        this.filter_citizen_number,
        this.nationalityid
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.citizens_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.citizens_data = {}
          this.total = 0

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    deleteCitizen(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            CitizensService.deleteCitizen(id)
              .then((resp) => {
                let i = this.citizens_data.map((item) => item.id).indexOf(id); // find index of your object
                this.citizens_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },

    getNationalites() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      NationalitiesService.getNationalitiesWithoutPagination()
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.nationalities_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
