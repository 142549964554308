<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
      طلبات استيراد المسجلين    
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden bRequest-b bRequest-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
          

            <div scope="col" class="w-3/12 px-2 py-3">المسجل</div>

            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
              التابع للمركز 
            </div>

            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
               التابع لبلدية
            </div>

            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
              ونقله للمركز 
            </div>
            

            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
              تاريخ الطلب
            </div>

            <div scope="col" class="w-1/12 px-2 py-3">الإجراءات</div>
          </div>
          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            لا توجد طلبات نقل بداخل البلدية
          </div>

          <div
            v-else
            v-for="(Request, personIdx) in vaccination_center_Requests_data"
            :key="Request.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >

          

            <div
              class="w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate"
            >
              {{ Request.citizen_name }}
            </div>

            <div
              class="w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ Request.from_the_health_center_name }}
            </div>

            <div
              class="w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ Request.from_the_municipal_name }}
            </div>


            <div
              class="w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ Request.to_the_health_center_name }}
            </div>

            <div
              class="w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ Request.insert_at }}
            </div>

            <div class="w-1/12 px-2 py-4">
              <div class="w-full flex items-center justify-between">
                <div v-if="Request.order_state == 1" class="w-12">
                  <button
                    title="الموافقة على الطلب"
                    class=""
                    @click="
                      RequestApprovalFromMunicipalToSendRequestToOther(Request.id)
                    "
                  >
                    <svg
                      class="w-6 h-6 stroke-current hover:text-green-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </button>

                  <button
                    title="رفض الطلب"
                    class=""
                    @click="
                      RequestRejectFromInternalTransferRequest(Request.id)
                    "
                  >
                    <svg
                      class="w-6 h-6 fill-current hover:text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_number"
        :per-page="page_size"
        :records="total"
        @paginate="getImportTransferRequests"
        class="z-10"
      />
    </div>
  </div>
</template>

<script>
import TransferRequestsService from "@/services/TransferRequestsService";

export default {
  computed: {},
  data() {
    return {
      vaccination_center_Requests_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,

      vaccination_center: this.$store.state.auth.role.guid,

      filter_box: false,

      filter_search: "",
      filter_is_approved: "",
    };
  },

  created() {
    this.getImportTransferRequests();
  },
  methods: {
    RequestRejectFromInternalTransferRequest(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      TransferRequestsService.RequestRejectFromInternalTransferRequest(id)
        .then((resp) => {
          setTimeout(() => {
            this.getImportTransferRequests();
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    RequestApprovalFromMunicipalToSendRequestToOther(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      TransferRequestsService.RequestApprovalFromMunicipalToSendRequestToOther(id)
        .then((resp) => {
          this.getImportTransferRequests();
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors,
          });
        });
    },

    getImportTransferRequests() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      TransferRequestsService.getImportTransferRequests(
        this.$store.state.auth.role.guid,
        this.page_number,
        this.page_size
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.vaccination_center_Requests_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();

          this.vaccination_center_Requests_data = {};
          this.total = 0;

          // this.$swal.fire({
          //     icon: "error",
          //     title: "...عذرا",
          //     text: err.response.data.message,
          // });
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  brequest-width: 1px;

  --tw-bRequest-opacity: 0;
  brequest-color: rgba(209, 213, 219, var(--tw-bRequest-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  brequest-radius: 0.375rem;
  overflow: hidden;
}
</style>
