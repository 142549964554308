<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        المسؤولين
      </h1>

      <!-- <router-link
                title="إضافة"
                :to="{ name: 'addadministratorfrommunicipals'  , params: { municipal_id:  municipal_id }}"
                class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                إضافة +
            </router-link> -->
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
              الرقم
            </div>
            <div scope="col" class="w-7/12 md:w-4/12 lg:w-7/12 px-6 py-3">
              الاسم
            </div>
            <div scope="col" class="w-3/12 lg:w-2/12 px-6 py-3 hidden md:block">
              اسم المستخدم
            </div>

            <!-- <div
                            scope="col"
                            class="w-3/12 lg:w-4/12 px-6 py-3 hidden md:block"
                        >
                            الصفة
                        </div> -->

            <div scope="col" class="w-5/12 md:w-2/12 px-6 py-3">الإجراءات</div>
          </div>
          <div
            v-for="(administrator, personIdx) in administratorsData"
            :key="administrator.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >
            <div
              class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden lg:block"
            >
              {{ personIdx + 1 }}
            </div>
            <div
              class="w-7/12 md:w-4/12 lg:w-7/12 px-6 py-4 whitespace-nowrap text-sm truncate"
            >
              {{ administrator.full_name }}
            </div>
            <div
              class="w-3/12 lg:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ administrator.usename }}
            </div>

            <!-- <div class="w-3/12 lg:w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ administrator.role_name }}
                        </div> -->

            <div class="w-5/12 md:w-2/12 px-6 py-4">
              <div class="w-full flex items-center justify-between">
                <!-- <router-link title="تعديل" :to="{ name: 'editadministratorbymunicipal', params: { municipal_id:  municipal_id, id: administrator.id },}" class="mx-2">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-blue-600"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </router-link> -->

                <button
                  title="إعادة تعيين كلمة مرور"
                  class=""
                  @click="
                    prepare_to_reset_password(
                      administrator.id,
                      administrator.usename
                    )
                  "
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-red-600"
                    width="800px"
                    height="800px"
                    viewBox="0 0 21 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      transform="matrix(0 1 1 0 2.5 2.5)"
                    >
                      <path
                        d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"
                      />

                      <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)" />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_num"
        :per-page="page_size"
        :records="total"
        @paginate="getAdministrators"
        class="z-10"
      />
    </div>





    <div
      v-if="prepare_to_reset_password_model"
      class="fixed z-40 bg-black bg-opacity-70 inset-0"
    >
      <div class="min-h-screen flex justify-center items-center">
        <form
          class="w-1/3 px-4 text-slate-500 dark:text-slate-400 border-2 border-gray-600 bg-gray-50 dark:bg-slate-900 py-4"
          @submit.prevent="reset_password()"
        >
          <div class="flex justify-between items-center">
            <div class="">
                إعادة تعيين كلمة مرور    
            </div>
            <div class="">
              <button
                @click="prepare_to_reset_password_model = false"
                class="hover:text-red-500 cursor-pointer"
              >
                X
              </button>
            </div>
          </div>

          <hr class="bg-gray-400 h-0.5 mt-4" />

          <div
            class="input mt-6 px-4"
          
          >
            <label for="new_password" class="block text-sm font-medium">
              كلمة المرور الجديدة
            </label>
            <input
              type="text"
              id="new_password"
              placeholder=" كلمة المرور"
              v-model="new_password"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 text-black bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
             
            />
          </div>

          <div
            class="input mt-6 px-4"
          
          >
            <label for="conform_new_password" class="block text-sm font-medium">
             تاكيد   كلمة المرور 
            </label>
            <input
              type="text"
              id="conform_new_password"
              placeholder="تاكيد كلمة المرور"
              v-model="conform_new_password"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 text-black bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
             
            />
          </div>


          <div class="text-white md:w-auto mt-8 w-full">
            <div
              class="w-full md:w-auto"
              :class="new_password != conform_new_password ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
              <button
                :disabled="new_password != conform_new_password"
                type="submit"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 text-sm font-medium focus:outline-none"
                :class="
                  new_password != conform_new_password
                    ? 'bg-gray-600'
                    : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                "
              >
                حفظ
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>




  </div>
</template>

<script>
import AdministratorsService from "@/services/AdministratorsService";
import MunicipalsService from "@/services/MunicipalsService";

export default {
  // components: { pagination: LaravelVuePagination },
  computed: {},
  data() {
    return {
      administratorsData: {},
      page_num: 1,
      page_size: 10,
      total: 0,

      municipal_id: this.$store.state.auth.role.guid,

      prepare_to_reset_password_model: false,

      new_password:'',
      conform_new_password:'',
      username_to_reset_password:'',
      id_to_reset_password:'',
    };
  },
  created() {
    this.getAdministrators();
  },
  methods: {
    prepare_to_reset_password(id, username) {
        this.prepare_to_reset_password_model = true

        this.conform_new_password = ''
        this.new_password = ''
        this.username_to_reset_password = username
        this.id_to_reset_password = id

        
      // /api/Administrator/rest_password
   
    },

    reset_password(){

        const data = {
            userName: this.username_to_reset_password,
            password: this.conform_new_password,
            };

        let loader = this.$loading.show({
              loader: this.loader,
            });

            AdministratorsService.resetPassword(data)
              .then((resp) => {
                this.prepare_to_reset_password_model = false
               
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();
                this.prepare_to_reset_password_model = false

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });



    },
    getAdministrators() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsService.getAdministratorsForMuncipal(
        this.page_num,
        this.page_size,
        this.municipal_id
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.administratorsData = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    deleteAdministrator(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            AdministratorsService.deleteAdministrator(id)
              .then((resp) => {
                let i = this.administratorsData
                  .map((item) => item.id)
                  .indexOf(id); // find index of your object
                this.administratorsData.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  /* margin-left: .5rem;
        margin-right: .5rem;*/
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
