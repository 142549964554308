<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة مسؤول ddd
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        autocomplete="off"
        @submit.prevent="addAdministrator"
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-4 gap-8">
          <div
            class="input"
            :class="{ invalid: $v.administrator.full_name.$error }"
          >
            <label
              for="administrator_full_name"
              class="block text-sm font-medium"
            >
              الاسم
            </label>
            <input
              type="text"
              id="administrator_full_name"
              placeholder="أكتب الاسم."
              v-model="administrator.full_name"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.full_name.$touch()"
            />
            <p
              v-if="!$v.administrator.full_name.minLength"
              class="text-xs text-red-500 mt-2"
            >
              يجب أن يحتوي الاسم على
              {{ $v.administrator.full_name.$params.minLength.min }} أحرف على
              الأقل.
            </p>
          </div>

          <div
            class="input"
            :class="{ invalid: $v.administrator.usename.$error }"
          >
            <label
              for="administrator_usename"
              class="block text-sm font-medium"
            >
              اسم المستخدم
            </label>
            <input
              autocomplete="off"
              type="text"
              id="administrator_usename"
              placeholder="أكتب اسم المستخدم."
              v-model="administrator.usename"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.usename.$touch()"
            />
            <p
              v-if="!$v.administrator.usename.minLength"
              class="text-xs text-red-500 mt-2"
            >
              يجب أن يحتوي الاسم على
              {{ $v.administrator.usename.$params.minLength.min }} أحرف على
              الأقل.
            </p>
          </div>

          <div
            class="input"
            :class="{ invalid: $v.administrator.password.$error }"
          >
            <label
              for="administrator_password"
              class="block text-sm font-medium"
            >
              كلمة المرور
            </label>
            <input
              type="password"
              id="administrator_password"
              placeholder="********"
              autocomplete="off"
              v-model="administrator.password"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.password.$touch()"
            />
            <p
              v-if="!$v.administrator.password"
              class="text-xs text-red-500 mt-2"
            >
              يجب أن يحتوي الاسم على أحرف على الأقل.
            </p>
          </div>

          <div
            class="input"
            :class="{ invalid: $v.administrator.password_confirmation.$error }"
          >
            <label
              for="administrator_password_confirmation"
              class="block text-sm font-medium"
            >
              تاكيد كلمة المرور
            </label>
            <input
              type="password"
              id="administrator_password_confirmation"
              placeholder="********"
              v-model="administrator.password_confirmation"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.password_confirmation.$touch()"
            />
            <p
              v-if="!$v.administrator.password_confirmation.sameAsPassword"
              class="text-xs text-red-500 mt-2"
            >
              يجب ان تكون كلمة المرور متطابقة
            </p>
          </div>

          <div
            class="input"
            :class="{ invalid: $v.administrator.birth_date.$error }"
          >
            <label
              for="administrator_birth_date"
              class="block text-sm font-medium"
            >
              تاريخ الميلاد
            </label>
            <input
              type="date"
              id="administrator_birth_date"
              placeholder="أكتب تاريخ الميلاد."
              v-model="administrator.birth_date"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.birth_date.$touch()"
            />
          </div>

          <div
            class="input"
            :class="{ invalid: $v.administrator.phone_number.$error }"
          >
            <label
              for="administrator_phone_number"
              class="block text-sm font-medium"
            >
              رقم الهاتف
            </label>
            <input
              type="text"
              id="administrator_phone_number"
              placeholder="أكتب رقم الهاتف."
              v-model="administrator.phone_number"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.administrator.phone_number.$touch()"
            />
            <p
              v-if="!$v.administrator.phone_number.minLength"
              class="text-xs text-red-500 mt-2"
            >
              يجب أن يحتوي الاسم على
              {{ $v.administrator.phone_number.$params.minLength.min }} أحرف على
              الأقل.
            </p>
          </div>
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              تعديل الببانات الشخصية
            </button>
          </div>
        </div>
      </form>

      <div class="">
        <div class="input col-span-4">
          <label for="permissions" class="block text-sm font-medium">
            الصلاحيات
          </label>
          <!-- flex items-center justify-start  -->
          <div
            id="roles"
            class="mt-1 w-full grid grid-cols-3 gap-4 border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >



            <div
              v-for="permission in administrator.not_permissions"
              :key="permission.id"
              class="flex items-center border border-solid m-1"
            >
              <button
                type="button"
                @click="addPermission(role.id, permission.id)"
                class="py-1 px-4 rounded focus:outline-none"
                :disabled="toReturnspecificPermission(permission.id)"
                :class="
                  toReturnspecificPermission(permission.id)
                    ? 'w-8/12 cursor-not-allowed'
                    : 'w-full cursor-pointer'
                "
              >
                {{ permission.name }}
              </button>

              <button
                type="button"
                @click="removePermission(permission.id)"
                class="w-4/12 text-centers py-1 px-4 bg-red-500"
                v-if="toReturnspecificPermission(permission.id)"
              >
                X
              </button>

              <br /><br />
            </div>





          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required, minLength, sameAs, integer } from "vuelidate/lib/validators";
import AdministratorsService from "@/services/AdministratorsService";

export default {
  created() {
    this.getAdministrator();
  },

  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,
      administrator: {
        id: "",
        usename: "",
        full_name: "",
        birth_date: "",
        phone_number: "",

        password: null,
        password_confirmation: null,

        roles: {},
        not_permissions: {},
        permissions: {},
      },

      roles: [],
      permissions: {},

      // loader: "dots",
    };
  },
  validations: {
    administrator: {
      full_name: {
        required,
        minLength: minLength(2),
      },
      usename: {
        required,
        minLength: minLength(2),
      },
      birth_date: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(10),
        integer,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },

  methods: {
    getPermission(role_id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministratorsService.getPermissionById(role_id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            let xnxx = res.data;
            this.administrator.not_permissions = xnxx;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getAdministrator() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministratorsService.getAdministrator(this.$route.params.id)
        .then((res) => {
          setTimeout(() => {
            console.log(res.data);
            let data = res.data;
            this.administrator.usename = data.usename;
            this.administrator.full_name = data.full_name;
            this.administrator.birth_date = data.birth_date;
            this.administrator.phone_number = data.phone_number;
            this.administrator.password = data.password;
            this.administrator.password_confirmation = data.password;

            loader.hide();

            this.administrator.permissions = data.role.permissions;

            this.getPermission("03a85b59-5047-48bd-a681-87d38bc207e5");
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    removePermission(permissionId) {
      this.administrator.roles.permissions =
        this.administrator.roles.permissions.filter(
          (data) => data.primation_id != permissionId
        );
    },

    addPermission(roleId, permissionId) {
      this.administrator.roles.permissions.push({
        id: permissionId,
        role_id: roleId,
      });
    },

    toReturnspecificPermission(permissionId) {
      if (
        this.administrator.permissions.find(
          (element) => element.id == permissionId
        ) == undefined
      ) {
        return false;
      } else {
        return true;
      }
    },

    // toReturnPermissions(x) {
    //   let role = this.administrator.roles.find((element) => element.id == x);
    //   return role.not_permissions;
    // },

    removeRole(id) {
      this.administrator.roles = this.administrator.roles.filter(
        (data) => data.id != id
      );
    },

    addRole(id, name) {
      this.administrator.roles = {
        id: id,
        name: name,
        permissions: [],
        not_permissions: [],
      };

      this.getPermission("03a85b59-5047-48bd-a681-87d38bc207e5");
    },

    getRoles() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministratorsService.getRoles(this.page_num, this.page_size)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.roles = res.data;
            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    updateAdministrator() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        administrator: {
          userId: this.$route.params.id,
          usename: this.administrator.usename,
          full_name: this.administrator.full_name,
          password: this.administrator.password,
          password_confirmation: this.administrator.password_confirmation,
          role: this.administrator.rolesId,
        },
        permissions: this.administrator.permissions,
      };

      AdministratorsService.updateAdministrator(data).then(
        (resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({ name: "administrators" });
          }, 10);
        },
        (err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        }
      );
    },
  },
};
</script>
